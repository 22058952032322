<!--
    获取当前点击节点的数据 this.handleTree()
    获取当前选择的社区编码 this.handleOrg()
-->


<template>
    <div :id="treeID" class="aside">
        <LiefengCascaderCity :orgCode="4401" :resetNum="0" :right="right" :width="width" @changeCasader="changeCasader" ref="cascaderCity" :value="value" :isShow="isShow"></LiefengCascaderCity>
        <Tree ref="trees" :data="treeData" @on-select-change="handleTree" :load-data="loadData" :style="height != null ? 'height:' + height + 'px' : ''" class="fadeInDown animated tree-div"></Tree>
    </div>
</template>

<script>
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
export default {
    components: {
        LiefengCascaderCity,
    },
    props: {
        height: "",
        width: {
            type: String,
            default: () => {
                return 300
            },
        },
        right: {
            type: String,
            default: () => {
                return 0
            },
        },
        value:[String,Array],
        isShow:{
            type: Boolean,
            default: () => {
                return false
            },
        },
    },
    data() {
        return {
            treeData: [],
            cascaderList: [],
            orgName: "",
            custGlobalId: "",
            oemCode: "",
            userSessionId: "",
            selectData: {},
            selectDataList: [],
        }
    },
    created() {
        this.custGlobalId = parent.vue.loginInfo.userinfo.custGlobalId
        this.oemCode = parent.vue.oemInfo.oemCode
        this.userSessionId = parent.vue.loginInfo.userinfo.id
    },
    mounted() {},
    methods: {
        // 级联选择框事件
        async changeCasader(val) {
            this.cascaderList = val

            if (val && val.length == 3) {
                this.getOrgName(this.$refs.cascaderCity.CascaderList, val[val.length - 1])
                this.$emit("handleOrg", {
                    orgCode: val[val.length - 1],
                    orgName: this.orgName,
                })
                this.treeData = [
                    {
                        title: this.orgName,
                        orgCode: val[val.length - 1],
                        regionCode: val[val.length - 1],
                        regionName: this.orgName,
                        parent: true,
                        selected: true,
                        loading:true
                    },
                ]
                let children = await this.getList(val[val.length - 1])
                if (children && children.length) {
                    this.treeData = [
                        {
                            title: this.orgName,
                            orgCode: val[val.length - 1],
                            regionCode: val[val.length - 1],
                            regionName: this.orgName,
                            loading: false,
                            children,
                            expand: true,
                            parent: true,
                            selected: true,
                        },
                    ]
                }
            }
        },
        // 获取社区名称
        getOrgName(data, orgCode) {
            if (data && data.length) {
                data.map(item => {
                    if (item.value == orgCode) {
                        this.orgName = item.orgName
                    } else {
                        if (item.children && item.children.length) {
                            this.getOrgName(item.children, orgCode)
                        }
                    }
                })
            }
        },
        // 获取社区名称
        changeSelected(data, regionCode) {
            if (data && data.length) {
                data.map(item => {
                    if (item.regionCode == regionCode) {
                        item.selected = true
                    } else {
                        if (item.children && item.children.length) {
                            this.changeSelected(item.children, regionCode)
                        }
                    }
                })
            }
        },
        // 点击了树节点
        handleTree(data) {
            if (data && data[0]) {
                this.selectData = data[0]
                // 点击节点
                this.$emit(
                    "handleData",
                    data[0],
                    this.$core.treeFindPath(this.treeData, treeFun => treeFun.regionCode === data[0].regionCode, "regionCode", "regionName", "regionType")
                )
                return
            } else {
                this.changeSelected(this.treeData, this.selectData.regionCode)
                // 取消节点
                this.$emit("handleCancelData", "")
                return
            }
        },
        // 树节点异步加载
        loadData(item, callback) {
            // 如果是第一层节点，不管
            if (item && item.parent) return
            let params = {
                oemCode: item.oemCode /**/,
                orgId: item.orgId,
                parentId: item.regionId ? item.regionId : 0, //如果父级是小区，传0，否则楼栋传regionId
                orgCode: item.orgCode,
            }
            this.$get("/gx/pc/region/selectRegionTree", params)
                .then(res => {
                    if (res.code === "200" && res.dataList != null) {
                        let arr = res.dataList.map(i => {
                            if (i.regionType === "4" || i.regionType === "0") {
                                //4：门牌号(无小区无楼栋)
                                return {
                                    ...i,
                                    title: i.regionName,
                                    regionId: i.regionId,
                                    oemCode: this.oemCode,
                                    orgCode: item.orgCode,
                                    projectCode: i.regionCode,
                                    regionType: i.regionType,
                                }
                            } else if (i.regionType === "3") {
                                //1：小区 2：小区的楼栋 3：单独的楼栋"
                                if (i.child) {
                                    return {
                                        ...i,
                                        title: i.regionName,
                                        regionId: i.regionId,
                                        oemCode: this.oemCode,
                                        orgCode: item.orgCode,
                                        projectCode: i.regionCode,
                                        regionType: i.regionType,
                                        orgId: i.orgId,
                                        children: [],
                                        loading: false,
                                    }
                                } else {
                                    return {
                                        ...i,
                                        title: i.regionName,
                                        regionId: i.regionId,
                                        oemCode: this.oemCode,
                                        orgCode: item.orgCode,
                                        projectCode: i.regionCode,
                                        regionType: i.regionType,
                                        orgId: i.orgId,
                                    }
                                }
                            } else if (i.regionType === "2") {
                                if (i.child) {
                                    return {
                                        ...i,
                                        title: i.regionName,
                                        regionId: i.regionId,
                                        oemCode: this.oemCode,
                                        orgCode: item.orgCode,
                                        projectCode: i.regionCode,
                                        regionType: i.regionType,
                                        orgId: i.orgId,
                                        children: [],
                                        loading: false,
                                    }
                                } else {
                                    return {
                                        ...i,
                                        title: i.regionName,
                                        regionId: i.regionId,
                                        oemCode: this.oemCode,
                                        orgCode: item.orgCode,
                                        projectCode: i.regionCode,
                                        regionType: i.regionType,
                                        orgId: i.orgId,
                                    }
                                }
                            } else if (i.regionType === "1") {
                                if (i.child) {
                                    return {
                                        ...i,
                                        title: i.regionName,
                                        regionId: i.regionId,
                                        children: [],
                                        loading: false,
                                        oemCode: this.oemCode,
                                        orgCode: item.orgCode,
                                        projectCode: i.regionCode,
                                        regionType: i.regionType,
                                        orgId: i.orgId,
                                        children: [],
                                    }
                                } else {
                                    return {
                                        ...i,
                                        title: i.regionName,
                                        regionId: i.regionId,
                                        oemCode: this.oemCode,
                                        orgCode: item.orgCode,
                                        projectCode: i.regionCode,
                                        regionType: i.regionType,
                                        orgId: i.orgId,
                                    }
                                }
                            }
                        })
                        item.children = arr
                        callback(item.children)
                    } else {
                        delete item.children
                        delete item.loading
                        this.$Message.error({
                            background: true,
                            content: "暂无下级数据",
                        })
                        callback(item)
                        return
                    }
                })
                .catch(err => {})
        },
        // 接口部分
        // 进来获取第一层级的数据
        async getList(orgCode) {
            this.$Message.loading({
                background: true,
                content: "正在加载数据",
            })
            try {
                return await this.$get("/gateway/org/pc/organization/selectOrganizationByOrgCode", {
                    orgCode,
                }).then(re => {
                    this.$Message.destroy()
                    if (re && re.code === "200" && re.dataList != null) {
                        return re.dataList.map(item => {
                            if (item.child) {
                                return {
                                    ...item,
                                    title: item.orgName,
                                    orgCode: item.orgCode,
                                    oemCode: item.oemCode,
                                    orgName: item.orgName,
                                    parentId: item.parentId,
                                    orgId: item.orgId,
                                    loading: false,
                                    regionCode: item.orgCode,
                                    regionName: item.orgName,
                                    regionType: item.orgLevel,
                                    children: [],
                                }
                            } else {
                                return {
                                    ...item,
                                    title: item.orgName,
                                    orgCode: item.orgCode,
                                    oemCode: item.oemCode,
                                    orgName: item.orgName,
                                    parentId: item.parentId,
                                    orgId: item.orgId,
                                    regionCode: item.orgCode,
                                    regionName: item.orgName,
                                    regionType: item.orgLevel,
                                }
                            }
                        })
                    }
                })
            } catch (err) {
                this.$Message.destroy()
                this.$Message.error({
                    background: true,
                    content: "数据加载失败",
                })
                return
            }
        },
    },
}
</script>

<style lang="less" scoped>
.aside {
    margin: 0 5px;
    min-width: 200px;
    position: relative;
    max-width: 300px;
    height: 100%;
    border-radius: 5px;
    .tree-div {
        height: calc(100% - 32px);
        border: 1px solid #ccc;
        border-top: none;
        overflow-y: scroll;
    }
}
/deep/ .ivu-tree-title-selected {
    background: #00ffff;
}

/deep/ .ivu-tree-title:hover {
    background: #f08080;
}
</style>
