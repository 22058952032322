<template>
    <div class="container-div">
        <div class="table-div">
            <div class="input-div">
                <Input style="width: 150px" v-model="searchName" placeholder="输入姓查询名" />
                <Input style="width: 150px; margin-left: 5px" v-model="searchPhone" placeholder="输入手机号查询" />
                <Select style="width: 180px; margin-left: 5px" v-model="searchLabelCode" placeholder="选择身份类型">
                    <Option :value="item.dictKey" v-for="(item, index) in identityType" :key="index">{{ item.dictValue }}</Option>
                </Select>
                <div class="org-select">
                    <div class="select-title" @click="openSelectOrg">
                        {{ titleName }}
                    </div>
                    <Icon style="margin:8px 10px 0 auto;" type="ios-arrow-down" @click="openSelectOrg" v-if="!showOrgSelect" />
                    <Icon style="margin:8px 10px 0 auto;" type="ios-arrow-up" @click="openSelectOrg" v-else />
                    <LiefengCascaderTree
                        @handleData="handleData"
                        @handleOrg="handleOrg"
                        class="org-tree"
                        height="600"
                        v-show="showOrgSelect"
                        v-if="onceShow"
                        @handleCancelData="openSelectOrg()"
                    ></LiefengCascaderTree>
                </div>
                <Button style="margin-left: 10px" type="primary" @click="search">查询</Button>
                <Button style="margin-left: 10px" type="info" @click="reset">重置</Button>
                <Button style="margin-left: 10px" type="info" @click="onDisplaySelectGroupMember">选择群成员</Button>
            </div>
            <Table
                height="660"
                :loading="loading"
                @on-select="tableSelect"
                @on-select-all="onSelectAll"
                @on-select-all-cancel="onSelectAllCancel"
                @on-select-cancel="onSelectCancel"
                :columns="talbeColumns"
                :data="tableData"
                stripe
                border
            ></Table>
            <Page
                style="float: right; margin: 10px"
                @on-change="morePageFn"
                :current="curPage"
                @on-page-size-change="PageSizeChange"
                :page-size="pageSize"
                :total="total"
                :page-size-opts="[20, 50, 100, 200]"
                size="small"
                show-total
                show-elevator
                show-sizer
            />
        </div>
        <div class="btn-div">
            <Button type="success" style="margin-bottom: 5px" @click="clearBotn">清空</Button>
        </div>
        <div class="select-div">
            <div class="select-header">
                <div class="select-title-left">已选择</div>
            </div>
            <div class="select-container" v-if="selectList && selectList.length > 0">
                <div :class="index % 2 == 0 ? 'background-div' : 'background-div-two'" v-for="(item, index) in selectList" :key="index">
                    <div class="item-left">
                        {{ item.name }}
                        <Icon style="margin-left: 5px" type="md-close" @click="deleteOnce(index, item)" v-if="!item._disabled" />
                    </div>
                </div>
            </div>
            <div class="none-table" v-else>暂无数据</div>
        </div>

        <CompModal ref="comp_modal" title="选择群成员" @on-scroll-bottom="onLoadGroups" width="500px" :top-level="true">
            <div class="tree-box" style="margin:0 10px">
                <Tree ref="group_tree" :data="groups" :load-data="onLoadGroupMember" show-checkbox></Tree>
            </div>
            <template #operate="evt">
                <div class="operate-btns">
                    <div class="operate-btn highlight" @click="onSubmitGroupMember">提交</div>
                    <div class="operate-btn" @click="evt.close">取消</div>
                </div>
            </template>
        </CompModal>
    </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable"
import LiefengCascaderTree from "@/components/LiefengCascaderTree"

import CompModal from "@/views/residentdatabase/components/CompModal"
import RequestPage from "@/views/jointly/utils/page"

export default {
    props: {
        groupId: {
            type: String,
            default: () => {
                return ""
            },
        },
        selectOrg: {
            type: String,
            default: () => {
                return ""
            },
        },
        showNum: {
            type: Number,
            default: () => {
                return 0
            },
        },
        userSelectList: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data() {
        //这里存放数据
        return {
            tableData: [],
            loading: false,
            curPage: 1,
            total: 0,
            pageSize: 20,
            page: 1,
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "类型",
                    minWidth: 200,
                    key: "userType",
                    align: "center",
                },
                {
                    title: "姓名",
                    minWidth: 110,
                    key: "name",
                    align: "center",
                },
                {
                    title: "手机号",
                    minWidth: 130,
                    key: "phone",
                    align: "center",
                },
            ],
            modeltrue: true,
            selectList: [],
            lastList: [],
            searchName: "",
            searchPhone: "",
            searchLabelCode: "",
            identityType: [],

            showOrgSelect: true,
            onceShow: true,

            orgCode: "",
            titleName: "请选择范围",
            orgName: "",
            streetCode: "",
            provinceCode: "",
            buildingCode: "",
            houseNo: "",

            // 社区沟通组
            groups: [],
        }
    },
    //方法集合
    methods: {
        // 获取沟通组列表
        getGroups() {
            this.reqGroupsPage = new RequestPage("/sendmsg/pc/chat/groupCreate/queryPage", {
                data: {
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                },
                size: 40,
                type: "block",
                onLoadBefore: () => {
                    this.$Message.loading({
                        content: "加载中...",
                        duration: 0,
                    })
                },
                onLoadAfter: () => {
                    this.$Message.destroy()
                },
                onChange: res => {
                    const groups = this.groups || []

                    for (let i = 0; i < res.length; i++) {
                        let v = res[i]

                        groups.push({
                            id: v.groupId,
                            title: v.groupName,
                            loading: false,
                            children: [],
                            type: "group",
                        })
                    }

                    this.groups = groups
                },
            })

            this.isLoadGroup = true
        },

        onLoadGroups() {
            this.reqGroupsPage.load()
        },

        /** 加载群成员 */
        async onLoadGroupMember(item, cb) {
            this.$Message.loading("加载中...")

            const getAllMember = async (list = [], page = 1) => {
                return new Promise(resolve => {
                    this.$get(`/sendmsg/pc/chat/groupCreate/queryMemberPage?account=&joinType=&name=&page=${page}&pageSize=20&labelCode=&hasSensitive=true`, {
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        groupId: item.id,
                    }).then(async res => {
                        if (res.code != 200) {
                            return this.$Message.error(res.desc)
                        }

                        for (let i = 0, l = res.dataList; i < l.length; i++) {
                            let v = l[i]

                            list.push({
                                id: v.custGlobalId,
                                title: v.name,
                                type: "member",
                            })
                        }

                        if (res.currentPage < res.maxPage) {
                            await getAllMember(list, ++page)
                        }

                        resolve(list)
                    })
                })
            }

            const mbs = await getAllMember()

            cb(mbs)

            this.$Message.destroy()

            if (mbs.length <= 0) {
                this.$Message.warning("当前沟通组暂无成员")
            }
        },

        onSubmitGroupMember() {
            const s = []

            for (let i = 0, l = this.$refs.group_tree.getCheckedNodes(); i < l.length; i++) {
                let v = l[i]

                // 勾选框为沟通组或成员为已选侧跳过
                if (v.type === "group" || this.isSelected(v.id)) {
                    continue
                }

                s.push({
                    name: v.title,
                    custGlobalId: v.id,
                })
            }

            this.$set(this, "selectList", this.selectList.concat(s))
            this.$emit("selectList", this.selectList)

            // 关闭弹窗
            this.$refs.comp_modal.close()
        },

        /** 校验是否已选 */
        isSelected(custGlobalId) {
            for (let i = 0, l = this.selectList; i < l.length; i++) {
                if (l[i].custGlobalId === custGlobalId) {
                    return true
                }
            }
            return false
        },

        /** 打开选择群成员窗口 */
        onDisplaySelectGroupMember() {
            this.$refs.comp_modal.display()

            if (!this.isLoadGroup) {
                this.getGroups()
            }
        },

        /** 刷新数据 */
        refresh() {
            this.groups = []
            this.isLoadGroup = false
        },

        //  删除已选的单个数据
        async deleteOnce(index, item) {
            this.selectList.splice(index, 1)
            let indexs = 0
            this.tableData.map((idx, indexs) => {
                if (idx.custGlobalId == item.custGlobalId) {
                    idx._checked = false
                    indexs = indexs
                }
            })
            this.$set(this.tableData, indexs, this.tableData[indexs])
        },
        //   方法部分
        handleData(val, val2) {
            let name = []
            this.streetCode = ""
            this.provinceCode = ""
            this.buildingCode = ""
            this.houseNo = ""
            if (val2 && val2.length) {
                val2.map(item => {
                    name.push(item.name)
                    if (item.type === "6") {
                        this.streetCode = item.code
                    } else if (item.type === "1") {
                        this.provinceCode = item.code
                    } else if (item.type === "2") {
                        this.buildingCode = item.code
                    } else if (item.type === "3") {
                        this.buildingCode = item.code
                    } else if (item.type === "4" || item.type === "0") {
                        this.houseNo = item.code
                    }
                })
            }
            this.titleName = name.join("-")
            this.openSelectOrg()
        },
        async handleOrg(val) {
            if (val && !val.type) {
                this.orgCode = val.orgCode
                this.titleName = val.orgName
                this.orgName = val.orgName
                await this.getList()
            }
        },
        // 点击打开选择社区方法
        openSelectOrg() {
            this.showOrgSelect = !this.showOrgSelect
        },
        // 获取字典接口的公共方法
        async getDicType(type, model) {
            await this.$get("/voteapi/api/common/sys/findDictByDictType", {
                dictType: type,
            }).then(res => {
                this[model] = res.dataList
            })
        },
        reset() {
            if (this.userSelectList && this.userSelectList.length) {
                this.selectList = this.userSelectList
            } else {
                this.selectList = []
            }
            this.lastList = []
            this.showOrgSelect = false
            this.onceShow = false
            this.searchName = ""
            this.searchPhone = ""
            this.searchLabelCode = ""
            this.titleName = ""
            this.tableData = []
            this.streetCode = ""
            this.provinceCode = ""
            this.buildingCode = ""
            this.houseNo = ""
            this.$nextTick(() => {
                this.onceShow = true
            })
        },
        search() {
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        // 点击清空按钮
        async clearBotn() {
            let list = []
            let list2 = []
            let listString2 = ""
            this.selectList.map(item => {
                if (item._disabled) {
                    list.push(item)
                    list2.push(item.custGlobalId)
                }
            })
            this.selectList = list
            this.$emit("selectList", this.selectList)
            let data = JSON.parse(JSON.stringify(this.tableData))
            listString2 = list2.join(",")
            this.tableData = data.map(item => {
                if (listString2.indexOf(item.custGlobalId) > -1) {
                    return {
                        ...item,
                        _checked: true,
                    }
                } else {
                    return {
                        ...item,
                        _checked: false,
                    }
                }
            })
        },
        PageSizeChange(val) {
            let params = {
                orgCode: this.selectOrg,
                pageSize: val,
                page: this.curPage,
            }
            this.getList(params)
        },
        morePageFn(val) {
            let params = {
                orgCode: this.selectOrg,
                page: val,
                pageSize: this.pageSize,
            }
            this.getList(params)
        },
        unique(arr) {
            const res = new Map()
            return arr.filter(arr => !res.has(arr.custGlobalId) && res.set(arr.custGlobalId, 1))
        },
        qufei(array1, array2) {
            let result = []
            for (var i = 0; i < array2.length; i++) {
                var obj = array2[i]
                var num = obj.custGlobalId
                var isExist = false
                for (var j = 0; j < array1.length; j++) {
                    var aj = array1[j]
                    var n = aj.custGlobalId
                    if (n == num && !array1[j]._disabled) {
                        isExist = true
                        break
                    }
                }
                if (!isExist) {
                    result.push(obj)
                }
            }
            return result
        },
        // 取消当前行
        onSelectCancel(selection, row) {
            this.selectList.map((item, index) => {
                if (item.custGlobalId == row.custGlobalId) {
                    this.selectList.splice(index, 1)
                }
            })
            this.$emit("selectList", this.selectList)
        },
        // 取消全选按钮
        onSelectAllCancel(val) {
            this.selectList = this.qufei(this.tableData, this.selectList)
            this.$emit("selectList", this.selectList)
        },
        // 全选
        onSelectAll(val) {
            val.map(item => {
                this.selectList.push(item)
            })
            this.selectList = this.$core.onlyList(this.selectList, "custGlobalId")
            this.tableData.map(item => (item._checked = true))
            this.$emit("selectList", this.selectList)
        },
        // 点击选择框回调事件
        tableSelect(val) {
            if (val && val.length != 0) {
                val.map(item => {
                    this.selectList.push(item)
                })
            }
            this.selectList = this.unique(this.selectList)
            this.$emit("selectList", this.selectList)
        },

        // 接口部分
        async getList(data) {
            this.loading = true
            let params = {
                orgName: this.orgName,
                communityCode: this.orgCode,
                pageSize: this.pageSize,
                page: this.page,
                communityProjectCode: this.orgCode,
                name: this.searchName,
                mobile: this.searchPhone,
                familyNo: "",
                userType: this.searchLabelCode,
                detailAddr: "",
                contact: "",
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                provinceCode: "", // 省
                cityCode: "", // 市
                countyCode: "", // 区
                roadCode: this.streetCode, // 街道
                buildingCode: this.buildingCode, // 楼栋
                houseNo: this.houseNo, // 房号
                projectCode: this.provinceCode,
            }
            await this.$get("/syaa/pc/sy/user/online/home/selectMailReceiverPage", params).then(res => {
                this.tableData = []
                this.loading = false
                let data = res.dataList.map(item => {
                    if (item.userType && item.userType != "") {
                        this.identityType.map(items => {
                            if (items.dictKey == item.userType) {
                                item.userType = items.dictValue
                            }
                        })
                    }
                    return {
                        ...item,
                    }
                })
                if (this.selectList && this.selectList.length != 0) {
                    // 将选中的数组进行存储，并且转换为数组，在转换为以逗号分隔的字符串
                    let List = []
                    let List2 = []
                    let listString = ""
                    let listString2 = ""
                    this.selectList.map(item => {
                        if (item._disabled) List.push(item.custGlobalId)
                        else List2.push(item.custGlobalId)
                    })
                    listString = List.join(",")
                    listString2 = List2.join(",")
                    // 循环遍历数组，匹配字符串，匹配成功就将复选框打勾，没有则复选框取消勾选
                    data.map(item => {
                        if (listString.indexOf(item.custGlobalId) > -1) {
                            item._checked = true
                            item._disabled = true
                        } else if (listString2.indexOf(item.custGlobalId) > -1) {
                            item._checked = true
                        } else {
                            item._checked = false
                        }
                    })
                } else {
                    data.map(item => {
                        item._checked = false
                    })
                }

                this.tableData = data
                this.total = res.maxCount
                this.curPage = res.currentPage
            })
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    async created() {
        await this.getDicType("USER_IMP_TYPE", "identityType")
    },
    components: {
        LiefengTable,
        LiefengCascaderTree,
        CompModal,
    },
    watch: {
        showNum() {
            this.reset()
        },
    },
}
</script>
<style lang="less" scoped>
//编写css
.container-div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    //   height: 750px;
    .table-div {
        width: 75%;
        .input-div {
            margin: 10px 0;
            display: flex;
        }
    }
    .btn-div {
        width: 5%;
        text-align: center;
        padding-top: 250px;
        margin-left: auto;
    }
    .select-div {
        border: 1px solid #e8eaec;
        width: 15%;
        position: relative;
        margin-top: 51px;
        margin-bottom: 44px;
        overflow-x: hidden;
        margin-left: auto;
        .select-header {
            background-color: #f8f8f9;
            top: 0;
            left: 0;
            position: absolute;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #515a6e;
            width: 100%;
            // background-color: #fff;
            z-index: 999;
            display: flex;
            justify-content: flex-start;
            text-align: center;
            border-top: 1px solid #e8eaec;
            border-left: 1px solid #e8eaec;
            border-right: 1px solid #e8eaec;
            .select-title-none {
                width: 10%;
                height: 100%;
            }
            .select-title-left {
                border-left: 1px solid #e8eaec;
                width: 45%;
                height: 100%;
            }
            .select-title-right {
                border-left: 1px solid #e8eaec;
                width: 45%;
                height: 100%;
            }
        }
        .select-container {
            z-index: 1;
            padding-top: 40px;
            height: 660px;
            overflow-y: scroll;
            .background-div {
                width: 100%;
                border-top: 1px solid #e8eaec;
                height: 48px;
                line-height: 48px;
                display: flex;
                justify-content: flex-start;
                text-align: center;
                .item-left {
                    margin-left: 3px;
                    width: 100%;
                }
            }
            .background-div-two {
                background-color: #f8f8f9;
                width: 100%;
                border-top: 1px solid #e8eaec;
                height: 48px;
                line-height: 48px;
                display: flex;
                justify-content: flex-start;
                text-align: center;
                .item-left {
                    margin-left: 3px;
                    width: 100%;
                }
            }
        }
        .none-table {
            text-align: center;
            color: #cccccc;
            margin-top: 60px;
        }
    }
    .org-select {
        width: 500px;
        margin-left: 5px;
        border: 1px solid #dcdee2;
        border-radius: 5px;
        color: #333;
        display: flex;
        cursor: pointer;
        user-select: none;
        position: relative;
        .select-title {
            width: 95%;
            height: 100%;
            line-height: 30px;
            padding: 0 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:hover {
            border: 1px solid #2d8cf0;
        }
        .org-tree {
            position: absolute;
            top: 35px;
            left: 0;
            z-index: 3;
            /deep/.tree-div {
                background: #fff;
            }
        }
    }
}
.background-div:last-child {
    border-bottom: 1px solid #e8eaec;
}

/deep/#modal_contentarea {
    padding: 0px;
}
</style>
